import { memo, useRef, useCallback } from 'react';
import { Input } from 'antd';

//* Style
import FormInputStyle from './style';

const FormInput = memo(({ isTextAreaInput, ...props }) => {
	//! Ref
	const ref = useRef();

	//! On Wheel
	const onWheel = useCallback((e) => e.preventDefault(), []);

	//! On Focus
	const onFocus = useCallback(() => {
		if (ref.current?.props?.type === 'number') {
			ref.current.input.addEventListener('wheel', onWheel);
		}
	}, [ref, onWheel]);

	//! On Blur
	const onBlur = useCallback(() => {
		if (ref.current?.props?.type === 'number') {
			ref.current.input.removeEventListener('wheel', onWheel);
		}
	}, [ref, onWheel]);

	return (
		<FormInputStyle>
			{isTextAreaInput ? (
				<Input.TextArea
					ref={ref}
					onBlur={onBlur}
					onFocus={onFocus}
					prefix={props.prefix}
					suffix={props.suffix}
					step={props.step && '0.1'}
					{...props}
				/>
			) : (
				<Input
					ref={ref}
					onBlur={onBlur}
					onFocus={onFocus}
					prefix={props.prefix}
					suffix={props.suffix}
					step={props.step && '0.1'}
					{...props}
				/>
			)}
		</FormInputStyle>
	);
});

export default FormInput;
