import styled from 'styled-components';

const FormInputStyle = styled.div`
	--inputPadBottom: var(--sp2-5x);
	--textareaHeight: var(--sp13x);

	input,
	textarea {
		padding: 0;
		padding-bottom: var(--inputPadBottom);
		border-radius: 0;
		background-color: transparent;
		border: unset;
		border-bottom: 1px solid var(--white1000);
		width: 100%;
		font-size: var(--p5);
		font-family: var(--avenir);
		line-height: var(--lineHeightL);
		font-weight: 400;
		color: var(--white1000);
		transition: unset;

		&::placeholder {
			font-size: var(--p5);
			line-height: var(--lineHeightL);
			font-family: var(--avenir);
			font-weight: 400;
			color: var(--white1000);
		}
	}

	textarea {
		height: var(--textareaHeight);
		min-height: var(--textareaHeight);
		max-height: var(--textareaHeight);
	}

	.ant-input-suffix,
	.ant-input-textarea-suffix {
		display: none;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--inputPadBottom: var(--sp2x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--inputPadBottom: var(--sp2x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--inputPadBottom: var(--sp2x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--inputPadBottom: var(--sp2x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--inputPadBottom: var(--sp2x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--inputPadBottom: var(--sp2x);
	}
`;

export default FormInputStyle;
