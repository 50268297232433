import { useImperativeHandle, useState, useCallback, forwardRef, useRef, useEffect } from 'react';
import { usePathname, useParams } from 'next/navigation';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

//* Component
import Text from '@/components/global/Text';

const FormContainer = forwardRef(({ layout, className, wrapClassName, resetFields = true, ...props }, ref) => {
	//! Ref
	const formRef = useRef();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Form
	const [form] = useForm();

	//! Send Form as Ref
	useImperativeHandle(ref, () => {
		return form;
	});

	//! State
	const [error, setError] = useState();

	//! Reset Form Fields in Router Changes
	useEffect(() => {
		form.resetFields();
		setError('');
	}, [pathname, params.lang]);

	//! On Value Change
	const onValuesChange = (newVal, values) => {
		props.onChange && props.onChange(newVal, values, form);
	};
	//! Finish failed
	const onFinishFailed = ({ errorFields }) => {
		form.scrollToField(errorFields[0].name, {
			block: 'center',
			inline: 'center',
		});
	};

	//! On Finish
	const onFinish = useCallback(
		(values) => {
			form.setFields([]);
			setError('');

			props
				.onFinish(values)
				.then((res) => {
					resetFields && form.resetFields();
					Promise.resolve(res);
				})
				.catch((err) => {
					console.error(err);

					if (err?.data) {
						const formValues = [];

						if (typeof err.data.message === 'string') {
							props.setError ? props.setError(err.data.message) : setError(err.data.message);
						}

						for (let key in values) {
							formValues.push({
								name: key,
								value: values[key],
								errors: [err.data.errors?.[key]]?.[0],
							});
						}

						form.setFields(formValues);
					}

					Promise.reject(err?.data);
				});
		},
		[props, form, error]
	);

	//! Disable number input scroll
	useEffect(() => {
		const handleMouseWheel = (e) => {
			e.target.blur();
		};

		const handleKeyDown = (e) => {
			const key = e.charCode || e.keyCode;

			if (key === 38 || key === 40) {
				e.preventDefault();
			}
		};

		const numberInputs = document.querySelectorAll('input[type="number"]');

		numberInputs.forEach((input) => {
			input.addEventListener('mousewheel', handleMouseWheel);
			input.addEventListener('keydown', handleKeyDown);
		});

		return () => {
			numberInputs.forEach((input) => {
				input.removeEventListener('mousewheel', handleMouseWheel);
				input.removeEventListener('keydown', handleKeyDown);
			});
		};
	}, []);

	return (
		<div
			ref={formRef}
			className={`form-block-wrapper ${wrapClassName || ''}`}>
			{error && (
				<Text
					className={'p p1 error-color font-avenir-medium text-center'}
					text='Error'
				/>
			)}

			<Form
				form={form}
				onFinish={onFinish}
				layout={layout || ''}
				className={className || ''}
				onValuesChange={onValuesChange}
				onFinishFailed={onFinishFailed}
				// onChange={props.onChange || null}
				initialValues={props.initialValues}>
				{props.children}
			</Form>
		</div>
	);
});

FormContainer.displayName = 'FormContainer';

export default FormContainer;
