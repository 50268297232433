import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50.5%;

	position: relative;
	width: 100%;
	padding-top: var(--proportion);

	img {
		object-fit: cover;
		object-position: center;
	}

	&.contain {
		img {
			object-fit: contain;
		}
	}
`;

export default ImageStyle;
