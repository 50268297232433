import styled from 'styled-components';

const FormItemStyle = styled.div`
	.ant-form-item-has-error {
		.ant-form-item-explain {
			margin-top: var(--sp1x);

			.ant-form-item-explain-error {
				color: var(--errorColor);
				font-size: var(--p7);
				font-family: var(--avenir);
				font-weight: 400;
				line-height: var(--lineHeightL);
			}
		}

		input,
		textarea,
		label {
			color: var(--errorColor) !important;
		}

		input,
		textarea {
			border-bottom: 1px solid var(--errorColor);

			&::placeholder {
				color: var(--errorColor) !important;
			}
		}
	}
`;

export default FormItemStyle;
