import { forwardRef, useRef, useImperativeHandle } from 'react';
import Image from 'next/image';

//* HOC's
import { withUIContext } from '@/context';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, className, width, height, priority = false, onClick, id, onMouseEnter, onMouseLeave }, ref) => {
	//! Ref
	const imgRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => imgRef.current, []);

	return (
		<ImageStyle
			id={id}
			ref={imgRef}
			style={{ width, height }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={`image-cont ${className || ''}`}>
			<Image
				fill
				onClick={onClick}
				priority={priority}
				alt={alt || 'Image'}
				sizes='(min-width: 320px) 100%'
				src={src || '/images/default.jpeg'}
				loading={priority ? 'eager' : 'lazy'}
				unoptimized={src && src.includes('.svg')}
			/>
		</ImageStyle>
	);
});

CustomImage.displayName = 'CustomImage';

export default withUIContext(CustomImage, ['winWidth']);
